import { Button, Col, DatePicker, Row, Select, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import {
  getAllLocationLevel1Data,
  getAllLocationLevel2Data,
  getAllPromoSmsCountries,
} from "../helpers";
import { getReportDataAreaWise } from "../helpers/apiHelpers/report";
import moment from "moment";

const columns = [
  {
    title: "State",
    dataIndex: "level1",
    key: "0",
    sorter: (a, b) => a.level1?.localeCompare(b.level1),
    defaultSortOrder: "ascend",
  },
  {
    title: "Suburb",
    dataIndex: "level2",
    key: "1",
    sorter: (a, b) => a.level2?.localeCompare(b.level2),
    defaultSortOrder: "ascend",
  },

  {
    title: "User Count",
    dataIndex: "userCount",
    key: "2",
    sorter: (a, b) => a.userCount - b.userCount,
    defaultSortOrder: "ascend",
  },
];

const columnsDiff = [
  {
    title: "District",
    dataIndex: "level1",
    key: "0",
    sorter: (a, b) => a.level1?.localeCompare(b.level1),
    defaultSortOrder: "ascend",
  },
  {
    title: "City",
    dataIndex: "level2",
    key: "1",
    sorter: (a, b) => a.level2?.localeCompare(b.level2),
    defaultSortOrder: "ascend",
  },

  {
    title: "User Count",
    dataIndex: "userCount",
    key: "2",
    sorter: (a, b) => a.userCount - b.userCount,
    defaultSortOrder: "ascend",
  },
];
const options = [];
for (let i = 10; i < 36; i++) {
  const value = i.toString(36) + i;
  options.push({
    label: `Long Label: ${value}`,
    value,
  });
}
const sharedProps = {
  mode: "multiple",
  style: {
    width: "100%",
  },
  options,
  placeholder: "Select Item...",
  maxTagCount: "responsive",
};

const UserCountReportAreaWise = () => {
  let { openNotification, handleError } = useContentContext();
  const [tableFinalData, setTableFinalData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [userTypeSelectorData, setUserTypeSelectorData] = useState([]);
  const [location1SelectorData, setLocation1SelectorData] = useState();
  const [location2SelectorData, setLocation2SelectorData] = useState();

  const [value, setValue] = useState(["a10", "c12", "h17", "j19", "k20"]);

  const [filterDataObject, setFilterDataObject] = useState({
    country_id: 0,
    user_type_id: null,
    location_level1: [],
    from_date: "",
    to_date: "",
  });

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    fetchCountryData();
  }, []);

  // fetch countries
  const fetchCountryData = async () => {
    try {
      await getAllPromoSmsCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  //fetch location level 1
  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  //fetch location level 2
  const fetchLocationLevel2Data = async (data) => {
    try {
      await getAllLocationLevel2Data({
        country_id: filterDataObject?.country_id,
        level1_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel2Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel2Data = (locations2) => {
    var locationLevel2Object = [];
    locations2?.forEach((location2) => {
      let data = {
        value: location2?.level2_id,
        label: location2?.level2_location,
      };
      locationLevel2Object.push(data);
    });
    setLocation2SelectorData(locationLevel2Object);
  };

  //fetchTableData
  const fetchTableData = async (data) => {
    setDataLoading(true);
    try {
      await getReportDataAreaWise(data)
        .then((response) => {
          if (response?.data.success) {
            setTableData(response.data.output ? response.data.output : []);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setTableData = (items) => {
    if (items?.length !== 0) {
      var tableData = [];

      items?.forEach((item, x) => {
        let data = {
          key: x,
          level1: item?.level1,
          level2: item?.level2,
          userCount: item?.user_count,
        };
        tableData.push(data);
      });
      setTableFinalData(tableData);
    } else {
      setTableFinalData([]);
    }
    setDataLoading(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  //report export
  const exportToCSV = async () => {
    const filterCountry = await countrySelectorData?.find(
      (item) => item?.value === filterDataObject?.country_id
    )?.label;
    const filterDate =
      filterDataObject?.from_date === "" && filterDataObject?.to_date === ""
        ? new Date().toLocaleString()
        : filterDataObject?.to_date !== ""
        ? filterDataObject?.from_date + "-" + filterDataObject?.to_date
        : filterDataObject?.from_date;
    const filterUserType =
      filterDataObject?.user_type_id === 2
        ? "Client"
        : filterDataObject?.user_type_id === 6
        ? "Tasker"
        : "All User";

    const headers =
      filterDataObject?.country_id === 2
        ? ["District", "City", "User Count"]
        : ["State", "Suburb", "User Count"];
    const title = `User Count Report (Area Wise) - ${filterCountry} - ${filterUserType} - ${filterDate}`;

    const csvRows = [title, "", headers.join(",")];

    tableFinalData.forEach((field, index) => {
      const rowValues = [
        field.level1?.replace(/,/g, " / "),
        field.level2?.replace(/,/g, " / "),
        field.userCount,
      ];
      csvRows.push(rowValues.join(","));
    });

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `User Count Report (Area Wise)-${filterCountry} -  ${filterUserType} - ${filterDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const selectProps = {
    value,
    onChange: setValue,
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        USER COUNT REPORT - AREA WISE
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <div className="flex justify-end items-end pb-5">
          <Button
            className="bg-primaryLight font-medium"
            type="primary"
            size="large"
            onClick={() => {
              exportToCSV();
            }}
            disabled={tableFinalData?.length === 0}
          >
            <p className="px-5">EXPORT CSV</p>
          </Button>
        </div>
        <Row gutter={8} className="w-full pb-5">
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Select
              name="country"
              placeholder="Country"
              className="!bg-secondaryWhite w-full"
              size="large"
              options={countrySelectorData}
              value={
                filterDataObject?.country_id === 0
                  ? null
                  : filterDataObject?.country_id
              }
              onChange={(e) => {
                setUserTypeSelectorData([
                  { label: "All", value: 0 },
                  { label: "Client", value: 2 },
                  { label: "Tasker", value: 6 },
                ]);
                fetchLocationLevel1Data(e);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  country_id: e,
                  user_type_id: null,
                  location_level1: [],
                }));
                fetchTableData({
                  ...filterDataObject,
                  country_id: e,
                  user_type_id: null,
                  location_level1: [],
                });
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Select
              {...sharedProps}
              {...selectProps}
              mode="multiple"
              placeholder={
                filterDataObject?.country_id === 2 ? "District" : "State"
              }
              className="!bg-secondaryWhite w-full"
              size="large"
              options={location1SelectorData}
              value={
                filterDataObject?.location_level1?.length === 0
                  ? []
                  : filterDataObject?.location_level1
              }
              onChange={(e) => {
                setTableFinalData([]);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  location_level1: e,
                }));

                fetchTableData({
                  ...filterDataObject,
                  location_level1: e,
                });
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Select
              name="userType"
              placeholder="User Type"
              className="!bg-secondaryWhite w-full"
              size="large"
              options={userTypeSelectorData}
              value={
                filterDataObject?.user_type_id === null
                  ? null
                  : filterDataObject?.user_type_id
              }
              onChange={(e) => {
                setTableFinalData([]);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  user_type_id: e,
                }));
                fetchTableData({
                  ...filterDataObject,
                  user_type_id: e,
                });
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <DatePicker
              size="large"
              placeholder="From Date"
              className="w-full"
              value={fromDate}
              disabledDate={(current) => {
                return current && current.isAfter(moment(), "day");
              }}
              disabled={
                filterDataObject?.country_id === 0 &&
                filterDataObject?.user_type_id === null &&
                filterDataObject?.location_level1?.length === 0
              }
              onChange={(dateValue, dateString) => {
                setFromDate(dateValue);
                setToDate();
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  from_date: dateString,
                  to_date: "",
                }));
                fetchTableData({
                  ...filterDataObject,
                  from_date: dateString,
                  to_date: "",
                });
              }}
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <DatePicker
              size="large"
              placeholder="To Date"
              className="w-full"
              value={toDate}
              disabledDate={(current) => {
                return (
                  (fromDate && current && current.isBefore(fromDate, "day")) ||
                  (current && current.isAfter(moment().endOf("day")))
                );
              }}
              disabled={
                filterDataObject?.from_date === "" ||
                (filterDataObject?.country_id === 0 &&
                  filterDataObject?.user_type_id === null &&
                  filterDataObject?.location_level1?.length === 0)
              }
              onChange={(dateValue, dateString) => {
                setToDate(dateValue);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  to_date: dateString,
                }));
                fetchTableData({
                  ...filterDataObject,
                  to_date: dateString,
                });
              }}
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Button
              size="large"
              className="w-full font-bold"
              onClick={() => {
                setFromDate();
                setToDate();
                setTableFinalData([]);
                setUserTypeSelectorData([]);
                setLocation1SelectorData([]);
                setLocation2SelectorData([]);
                setFilterDataObject({
                  country_id: 0,
                  user_type_id: null,
                  location_level1: [],
                  from_date: "",
                  to_date: "",
                });
              }}
            >
              CLEAR
            </Button>
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={tableFinalData}
            columns={filterDataObject?.country_id === 2 ? columnsDiff : columns}
            loading={dataLoading}
            showSorterTooltip={false}
          />
        </div>
      </Content>
    </>
  );
};

export default UserCountReportAreaWise;
