import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { getAllPromoSmsCountries } from "../helpers";
import { useContentContext } from "../providers/ContentContext";
import { BsEye } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import NewClientNotification from "../components/clientNotification/NewClientNotification";
import ViewClientNotification from "../components/clientNotification/ViewClientNotification";
import { getAllNotificationTableData } from "../helpers/apiHelpers/notificationHelpers";

const NotificationClient = () => {
  let { openNotification, handleError } = useContentContext();

  const [openCloseNewNotificationDrawer, setOpenCloseNewNotificationDrawer] =
    useState(false);
  const [openCloseViewNotificationDrawer, setOpenCloseViewNotificationDrawer] =
    useState(false);

  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(1);
  const [searchText, setSearchText] = useState();
  const [valueForNotificationSearch, setValueForNotificationSearch] =
    useState();

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [notificationID, setNotificationID] = useState();

  useEffect(() => {
    fetchTableData(1);
    fetchCountryData();
  }, []);

  const fetchCountryData = async () => {
    try {
      await getAllPromoSmsCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const fetchTableData = async (countryId) => {
    try {
      await getAllNotificationTableData({
        user_type_id: 2,
        country_id: countryId,
      })
        .then((response) => {
          if (response?.data.success) {
            setData(response?.data.output);
            setValueForNotificationSearch(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (items) => {
    if (items.length !== 0) {
      var tableData = [];

      items?.forEach((item, x) => {
        let data = {
          key: x,
          title: item?.title,
          date: item?.date_time,
          action: (
            <BsEye
              className="cursor-pointer text-2xl text-primaryLight"
              onClick={() => {
                handleOpenViewNotificationDrawer(item);
              }}
            />
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
      setDataLoading(false);
    } else {
      setTableData([]);
      setDataLoading(false);
    }
    setDataLoading(false);
  };

  //new notification
  const handleOpenCloseNewNotificationDrawer = () => {
    setOpenCloseNewNotificationDrawer(false);
    fetchTableData(1);
    setSelectedCountry(1);
  };

  //view notification
  const handleOpenViewNotificationDrawer = (item) => {
    setNotificationID(item?.notification_id);
    setOpenCloseViewNotificationDrawer(true);
  };
  const handleCloseViewNotificationDrawer = () => {
    setOpenCloseViewNotificationDrawer(false);
  };

  //notification search
  const search = (text) => {
    let filtered = valueForNotificationSearch?.filter((o) => {
      return o.title.toLowerCase().includes(text.toLowerCase());
    });
    setData(filtered);
  };

  //pagination
  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  //selector filter
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        CLIENT-NOTIFICATION
      </div>
      <Content className="rounded-lg table-status-button mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start  lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Notification..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>

          <Col>
            <Button
              className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
              type="primary"
              size="large"
              onClick={() => {
                setOpenCloseNewNotificationDrawer(true);
              }}
              icon={<MdAdd className="text-2xl font-bold" />}
            >
              <p className=" text-secondaryWhite text-[18px] font-semibold">
                NEW NOTIFICATION
              </p>
            </Button>
          </Col>
        </Row>

        <p className="pb-1">Country</p>
        <Row className="flex flex-col gap-2 lg:gap-0 items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col>
            <Select
              name="country"
              placeholder="Select Country"
              required
              className="!bg-secondaryWhite w-full min-w-60"
              size="large"
              options={countrySelectorData}
              value={selectedCountry}
              onSelect={(e) => {
                setSelectedCountry(e);
                fetchTableData(e);
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>

          <Col>
            <Button
              className="flex flex-row font-medium !pt-2 !pb-9"
              size="large"
              onClick={() => {
                setSearchText();
                setSelectedCountry(1);
                fetchTableData(1);
              }}
            >
              <p className="text-black text-[18px] font-semibold">
                Clear Filter
              </p>
            </Button>
          </Col>
        </Row>

        <Table
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          loading={dataLoading}
          pagination={{
            size: "small",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            showQuickJumper: true,
            itemRender: CustomPagination,
          }}
        />
      </Content>

      {openCloseNewNotificationDrawer && (
        <NewClientNotification
          open={openCloseNewNotificationDrawer}
          onClose={handleOpenCloseNewNotificationDrawer}
        />
      )}

      {openCloseViewNotificationDrawer && (
        <ViewClientNotification
          open={openCloseViewNotificationDrawer}
          onClose={handleCloseViewNotificationDrawer}
          notificationID={notificationID}
        />
      )}
    </>
  );
};

export default NotificationClient;

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "0",
    sorter: (a, b) => a.title?.localeCompare(b.title),
    defaultSortOrder: "ascend",
  },

  {
    title: "Created Date",
    dataIndex: "date",
    key: "1",
    sorter: (a, b) => a.date?.localeCompare(b.date),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];
