import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {
  Category,
  Dashboard,
  Login,
  Country,
  State,
  Suburb,
  JobType,
  ForgotPassword,
  JobData,
  Customer,
  Admin,
  Configuration,
  Terms,
  Tradie,
  PasswordReset,
  SupportTradie,
  SupportCustomer,
  JobsOverview,
  VerificationCenter,
  Blog,
  PackageConfiguration,
  AppDownload,
} from "./pages";
import { Main } from "./layouts";
import { getLocalStoragedata } from "./helpers/StorageHelper";
import AdminRoles from "./pages/AdminRoles";
import PrivateRoute from "./services/routes/PrivateRoute";
import NotPermission from "./pages/NotPermission";
import CreateNewPassword from "./pages/CreateNewPassword";
import PromoSms from "./pages/PromoSms";
import UserCountReport from "./pages/UserCountReport";
import AppDownloadGraph from "./pages/AppDownloadGraph";
import AppRegisterGraph from "./pages/AppRegisterGraph";
import UserCountReportTypewise from "./pages/UserCountReportTypewise";
import UserCountReportAreaWise from "./pages/UserCountReportAreaWise";
import NotificationTasker from "./pages/NotificationTasker";
import NotificationClient from "./pages/NotificationClient";

function App() {
  const token = getLocalStoragedata("token");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          exact
          path="/create-new-password"
          element={<CreateNewPassword />}
        />
        {(token !== null) & (token !== "") ? (
          <Route path="/" element={<Main />}>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Dashboard />} />

            <Route
              exact
              path="/category"
              element={
                <PrivateRoute element={<Category />} requiredRole={24} />
              }
            />
            <Route
              path="/job-type"
              element={<PrivateRoute element={<JobType />} requiredRole={28} />}
            />
            <Route
              exact
              path="/jobs-overview"
              element={
                <PrivateRoute element={<JobsOverview />} requiredRole={32} />
              }
            />
            {/* user */}
            <Route
              exact
              path="/client"
              element={<PrivateRoute element={<Customer />} requiredRole={8} />}
            />
            <Route
              exact
              path="/tasker"
              element={<PrivateRoute element={<Tradie />} requiredRole={56} />}
            />
            <Route
              exact
              path="/admin"
              element={<PrivateRoute element={<Admin />} requiredRole={60} />}
            />

            {/* verfication */}
            <Route
              exact
              path="/verfication-center"
              element={
                <PrivateRoute
                  element={<VerificationCenter />}
                  requiredRole={48}
                />
              }
            />

            {/* master data */}
            <Route
              exact
              path="/country"
              element={<PrivateRoute element={<Country />} requiredRole={12} />}
            />
            <Route
              exact
              path="/states"
              element={<PrivateRoute element={<State />} requiredRole={16} />}
            />
            <Route
              exact
              path="/suburb"
              element={<PrivateRoute element={<Suburb />} requiredRole={20} />}
            />
            <Route
              exact
              path="/units"
              element={<PrivateRoute element={<JobData />} requiredRole={36} />}
            />
            <Route
              exact
              path="/admin-roles"
              element={
                <PrivateRoute element={<AdminRoles />} requiredRole={4} />
              }
            />

            {/* CMS */}
            <Route
              exact
              path="/blog"
              element={<PrivateRoute element={<Blog />} requiredRole={64} />}
            />
            <Route
              exact
              path="/promo-sms"
              element={
                <PrivateRoute element={<PromoSms />} requiredRole={68} />
              }
            />

            {/* NOTIFICATION */}
            <Route
              exact
              path="/tasker-notification"
              element={<NotificationTasker />}
            />
            <Route
              exact
              path="/client-notification"
              element={<NotificationClient />}
            />

            {/* <Route
              exact
              path="/package-configuration"
              element={<PackageConfiguration />}
            /> */}

            {/* report */}
            <Route
              exact
              path="/job-count"
              element={
                <PrivateRoute element={<UserCountReport />} requiredRole={72} />
              }
            />
            <Route
              exact
              path="/user-count-job-wise"
              element={
                <PrivateRoute
                  element={<UserCountReportTypewise />}
                  requiredRole={72}
                />
              }
            />
            <Route
              exact
              path="/user-count-area-wise"
              element={
                <PrivateRoute
                  element={<UserCountReportAreaWise />}
                  requiredRole={72}
                />
              }
            />

            {/* graph */}
            <Route
              exact
              path="/app-download-graph"
              element={
                <PrivateRoute
                  element={<AppDownloadGraph />}
                  requiredRole={76}
                />
              }
            />
            <Route
              exact
              path="/app-register-graph"
              element={
                <PrivateRoute
                  element={<AppRegisterGraph />}
                  requiredRole={76}
                />
              }
            />

            {/* support */}
            <Route
              exact
              path="/support-tasker"
              element={
                <PrivateRoute element={<SupportTradie />} requiredRole={44} />
              }
            />
            <Route
              exact
              path="/support-client"
              element={
                <PrivateRoute element={<SupportCustomer />} requiredRole={44} />
              }
            />

            {/* settings */}
            <Route
              exact
              path="/terms"
              element={<PrivateRoute element={<Terms />} requiredRole={52} />}
            />
            <Route
              exact
              path="/configuration"
              element={
                <PrivateRoute element={<Configuration />} requiredRole={40} />
              }
            />
            <Route
              exact
              path="/app-download"
              element={
                <PrivateRoute element={<AppDownload />} requiredRole={84} />
              }
            />
            <Route
              exact
              path="/reset-password"
              element={
                <PrivateRoute element={<PasswordReset />} requiredRole={80} />
              }
            />
          </Route>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
        <Route path="/not-permission" element={<NotPermission />} />

        <Route exact path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
